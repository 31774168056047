import React from 'react'
// import Utilities from './helpers/utilities'
// import ClientStore from './helpers/clientstore'
// import HttpFetch from './helpers/httpfetch'
// import Covid from './covid'
import * as Constants from './constants'
import { motion } from 'framer-motion'

export default class About extends React.Component {
  // u = new Utilities();
  render() {

    return (
      <motion.div
        initial={Constants.PAGE_TRANSITION.out}
        animate={Constants.PAGE_TRANSITION.in}
        exit={Constants.PAGE_TRANSITION.out}
      // variants={Constants.PAGE_TRANSITION}        
      >
        <div className="row mt-5 pt-5">
          <div className="col-12">
            <h1 className="display-4">Learning By Doing: React</h1>
          </div>
          <div className="col mt-4">
            <p>
              A client application originally written in <code>Typescript</code> and <code>jQuery</code>, consuming and curating public API data, displayed using Google Charts API.
            </p>
            <ul>
              <li><a target="_tech" href="https://reactjs.org/">React</a></li>
              <li><a target="_tech" href="https://developers.google.com/chart">Google Charts</a></li>
              <li><a target="_tech" href="https://marketingplatform.google.com/about/analytics/">Google Analyics::Event Handling</a></li>
              <li><a target="_tech" href="https://www.framer.com/motion/">Framer Motion</a></li>
              <li><a target="_tech" href="https://azure.microsoft.com/en-us/services/app-service/static/">Azure</a></li>
              <li><a target="_tech" href="https://github.com/features/actions">Github Actions</a>(CI/CD)</li>
            </ul>
            <h2>Credits:</h2>
            <ul>
              <li>Data source: <a title="Covid data source" href="https://covidtracking.com/" target="_credits">The Covid Tracking Project</a></li>
              <li>Dev: <a target="_credits" title="Link to Stack Overflow resume" href="https://stackoverflow.com/cv/edsf">Ed Chavez</a></li>
            </ul>
          </div>
        </div>
      </motion.div>
    )
  }

}

