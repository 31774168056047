/**
 * @description Utility to load scripts dynamically as an alternative to loading in html
 *
 * @export
 * @class ScriptLoader
 * @author edchavez at gmail dot com
 */
export default class ScriptLoader {

  static LoadScript(src) {
    // const myName = this.name;
    // console.log(`${myName} invoked...`)

    return new Promise(function (resolve, reject) {
      const script = document.createElement("script");
      script.src = src;
      script.addEventListener('load', function(){
        resolve(`Successfully loaded ${src}`);
      })
      script.addEventListener('error', function(e){
        reject(`Load error! Script ${src} not loaded: ${e}`);  
      })
      document.body.appendChild(script);

    })
  }
}