export const WHO_AM_I = 'Ed Chavez';

export const CURRENT_YEAR = new Date().getFullYear();

export const PAGE_TRANSITION = {
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
}
