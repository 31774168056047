import { default as Persistence } from './helpers/clientstore'
import { default as Utils } from './helpers/utilities'
import { default as Http } from './helpers/httpfetch'

export default class Covid {
  Storage;
  ExpHours;

  PayloadKeyName = 'covid-data';
  ExpKeyKeyName = 'covid-expires';

  constructor(exp = 24) {
    this.ExpHours = exp;
    this.Storage = new Persistence("covid.edchavez.dev");
  }

  /**
   *
   *
   * @returns {Promise}
   * @memberof Covid
   */
  FetchStatesData() {
    if (this.CreateOrUpdate()) {
      console.log("expired or null data, fetching....%o", this.CreateOrUpdate());

      return new Http("https://api.covidtracking.com/v1/states/current.json", "GET")
        .GetDataAsync();
      // .then(data => {
      //   this.SetData(data);
      // });
    }
  }

  /**
   *
   *
   * @returns {object}
   * @memberof Covid
   */
  GetStoredData() {
    return this.Storage.GetData();
  }

  /**
   *
   *
   * @returns {string} Expiration Date
   * @memberof Covid
   */
  GetDataExpiration() {
    let rawData = this.GetStoredData();
    return new Date(rawData[this.ExpKeyKeyName]).toLocaleString();
  }

  /**
   * 
   *
   * @returns {Array<Object>}
   * @memberof Covid
   */
  GetCovidData() {
    let rawData = this.GetStoredData();
    return rawData[this.PayloadKeyName];
  }

  /**
   *
   *
   * @returns {Array<Object>}
   * @memberof Covid
   */
  GetStatesData() {
    let existingData = this.GetCovidData();
    let result = [];
    for (let st of existingData) {
      let state = {};
      let detail = {};
      let localeDate = Utils.ReturnLocaleDateString(st['dateChecked']);
      state.state = st['state'];
      detail.totalTestResults = st['totalTestResults'];
      
      detail.positive = Utils.IsNumeric(st['positive']) ? st['positive'] : 0;
      detail.negative = Utils.IsNumeric(st['negative']) ? st['negative'] : 0;
      detail.noNegativeData = !Utils.IsNumeric(st['negative']);

      // console.log(`${state.state} positive is ${detail.positive} (${Utils.IsNumeric(st['positive'])}) and negative is ${detail.negative} (${Utils.IsNumeric(st['negative'])})`);

      detail.death = st['death'];
      detail.hospitalized = st['hospitalized'] == null ? "NA" : st['hospitalized'];
      detail.dateChecked = localeDate == null ? "NA" : localeDate;
      state.detail = detail;
      result.push(state);

    }
    return result;
  }

  /**
   * 
   *
   * @returns {Array<Object} Ascending sort state data (by State)
   * @memberof Covid
   */
  SortByStateDeaths() {
    let states = this.GetStatesData();
    return states.sort((x, y) => x.detail.death - y.detail.death);
  }

  /**
   * 
   *
   * @returns {Array<Object} Descending sort state data (by State)
   * @memberof Covid
   */
  SortByStateDeathsDesc() {
    return this.SortByStateDeaths().reverse();
  }

  /**
   * 
   *
   * @returns {number}
   * @memberof Covid
   */
  GetTotalDeaths() {
    let states = this.GetStatesData();
    return states.reduce(function (acc, item) {
      return acc + item.detail.death;
    }, 0);
  }

  /**
   *
   *
   * @returns {number}
   * @memberof Covid
   */
  GetTotalTested() {
    let states = this.GetStatesData();
    return states.reduce(function (acc, item) {
      return acc + item.detail.totalTestResults;
    }, 0);
  }

  /**
   * 
   *
   * @returns {number}
   * @memberof Covid
   */
  GetTotalPositive() {
    let states = this.GetStatesData();
    return states.reduce(function (acc, item) {
      return acc + item.detail.positive;
    }, 0);
  }

  /**
   *
   *
   * @returns {number}
   * @memberof Covid
   */
  GetTotalNegative() {
    let states = this.GetStatesData();
    return states.reduce(function (acc, item) {
      return acc + item.detail.negative;
    }, 0);
  }

  /**
   *
   *
   * @param {string} state US State name in "US-XX" format
   * @returns {Object}
   * @memberof Covid
   */
  GetStateData(state) {
    // console.log(`Input param was ${state}`);
    let val = state.split("-");
    let lookup = val.length == 2 ? val[1] : val[0];

    if (lookup != "") {
      lookup = lookup.toUpperCase();
      let states = this.GetStatesData();
      let stateArray = states.map(function (d, i) {
        return d.state;
      })
      if (stateArray.includes(lookup)) {
        return states.filter(function (s) { return s.state == lookup; })[0];
      }
    }
    return null;
  }

  /**
   *
   *
   * @returns {boolean}
   * @memberof Covid
   */
  CreateOrUpdate() {
    let existingData = this.GetCovidData();

    if (existingData == null || Object.keys(existingData).length == 0) {
      return true;
    } else if (this.Storage.IsExpired(this.ExpKeyKeyName)) {
      this.Storage.Clear();
      return true;
    } else {
      return false;
    }
  }

  /**
   * 
   *
   * @param {*} data The object to stringify and store
   * @memberof Covid
   */
  SetData(data) {
    this.Storage.SetData(data, this.PayloadKeyName);
    this.Storage.SetExpiration(this.ExpHours, this.ExpKeyKeyName);
  }





}
