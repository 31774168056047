
import Utilities from './utilities'

/**
 * @description Web Storage Management
 * @author Ed Chavez 02-2020
 */
export default class ClientStore {

  _storageName;
  _iStorageAvailable = false;

  /**
   * @description Contructor
   * @param keyName The key name of your local storage object
   */
  constructor(keyName) {
    if (this.JankyBrowserTest()) {
      this._iStorageAvailable = true
      this._storageName = keyName;
    } else {
      throw Error("No local storage support in " + window.navigator.userAgent);
    }
  }

  /**
   * @description Get localStorage object (or initialize if key name does not exist)
   * @returns Object from local storage
   */
  GetData() {
    return this.Deserialize() || {};
  }

  /**
   * @description Sets localStoreage object data.
   * NOTE: This is specific to "data" property of the localStorage object,
   *  as opposed to other properties that may extend it in the future.
   * @param obj The data (aka "payload") to store in localStorage
   * @param dataKey The data payload key name
   */
  SetData(obj, dataKey) {
    if (obj != null) {
      var storeObj = this.GetData();
      storeObj[dataKey] = obj;
      window.localStorage.setItem(this._storageName, this.Serialize(storeObj));
    } else {
      Utilities.ErrLog("Cannot set web storage, no data to store ", obj);
    }
  }

  /**
   * @description This clears an object from localStorage
   */
  Clear() {
    window.localStorage.removeItem(this._storageName);
  }

  /**
   * @description Set localStorage Expiration data
   * NOTE: This is specific to "expires" property of the localStorage object,
   *  as opposed to other properties that may extend it in the future.
   * @param expireHours Hours from current client time
   * @param expKeyName Expiration key name
   */
  SetExpiration(expireHours, expKeyName) {
    var currentTime = Date.now();
    var buffer = Utilities.IsNumeric(expireHours) ? expireHours : 1;
    var storeObj = this.GetData();
    storeObj[expKeyName] = currentTime + (buffer * 3600000);
    window.localStorage.setItem(this._storageName, this.Serialize(storeObj));
  }

  /**
   * @description Checks if the localStorage Object is expired.
   * NOTE: It does NOT implement any action on the storage object.
   *  It only determines whether or not the object is EXPIRED and LEAVES implementation to callers.
   * @returns True or False
   */
  IsExpired(expKeyName) {
    var storeObj = this.GetData();
    if (storeObj[expKeyName] != null && Utilities.IsNumeric(storeObj[expKeyName])) {
      var currentTime = Date.now();
      return storeObj[expKeyName] <= currentTime;
    }
    return false;
  }

  /**
   * Check if an expiration value has already been previously set
   * @returns True or False
   */
  HasExpiration(expKeyName) {
    var storeObj = this.GetData();
    return storeObj[expKeyName] != null && Utilities.IsNumeric(storeObj[expKeyName]);
  }

  /**
   * @description Preps data for storage (string)
   * @param obj The object to serialize (to string)
   * @returns String representation of object
   */
  Serialize(obj) {
    if (obj != null) {
      return Utilities.SerializeObject(obj)
    } else {
      Utilities.ErrLog("Cannot serialize, object param is null: ", obj);
    }

  }

  /**
   * @description Reads a storage object
   * @returns JSON representation of object
   */
  Deserialize() {
    return Utilities.DeserializeObject(window.localStorage.getItem(this._storageName));
  }

  /**
   * @description Do we have localStorage?
   * REF: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
   * @returns True or False. Whether or not the current browser supports local storage.
   */
  JankyBrowserTest() {
    try {
      var store = window.localStorage, x = "__store_test__";
      store.setItem(x, x);
      store.removeItem(x);
      return true
    } catch (exc) {
      Utilities.ErrLog("localStorage error.", exc);
      return false;
    }
  }

}
