import React from 'react';
import {HashRouter as Router, Switch, Route, Link} from 'react-router-dom'
// import logo from './logo.svg';
import About from './About'
// import Contact from './Contact'
import GoogleMap from './GoogleMap'
import StateDetail from './StateDetail'
import * as Constants from './constants'
import {AnimatePresence, motion} from 'framer-motion'




export default function App() {



  return (

    <Router>

      <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark shadow">
        <Link to="/about" className="navbar-brand">Ed Chavez</Link>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" className="collapse navbar-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">US Data</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">About</Link>
            </li>
            {/* <li className="nav-item">
              <Link to="/contact" className="nav-link">Contact</Link>
            </li> */}
          </ul>
        </div>
      </nav>


      <div className="container-fluid">

        <AnimatePresence>

          <Switch>

            <Route path="/about">
              <About />
            </Route>

            {/* <Route path="/contact">
              <Contact />
            </Route> */}

            {/* <Route path="/state/:query?">
            <StateDetail />
          </Route> */}


            <Route
              path='/state/:query?'
              render={(props) => <StateDetail {...props} opt={false} />}
            />

            <Route path="/">
              <GoogleMap />

              {/* <div className="row">
                <div className="col">
                  <div id="state-detail"></div>
                </div>
              </div> */}
            </Route>


          </Switch>

        </AnimatePresence>

        {/*<div className="row">
          <div className="col bg-dark">
            <h1>foo</h1>
          </div>
        </div>
        <div className="row">
          <div className="col bg-primary">
            Primary
          </div>
          <div className="col bg-secondary">
            Secondary
          </div>
          <div className="col bg-info">
            Info
          </div>
        </div>
        <div className="row">
          <div className="col bg-primary-brown">
            Primary Brown
          </div>
          <div className="col bg-secondary-brown">
            Secondary brown
          </div>
          <div className="col bg-info-brown">
            Info brown
          </div>
        </div>
        <div className="row">
          <div className="col bg-dark">
            dark
          </div>
          <div className="col bg-success">
            Success
          </div>
          <div className="col bg-warning">
            warning
          </div>
        </div>

        */}

        {/* <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo my-3" alt="logo" />
            <p className="my-3">
              Edit <code>src/App.js</code> and save to reload.
        </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn React
        </a>
          </header>
        </div> */}

        <div className="row mt-5">
          <div className="col">
            <hr />
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <p className="small">&copy; {Constants.CURRENT_YEAR} <a target="_stackoverflow" title="Link to Stack Overflow resume" href="https://stackoverflow.com/cv/edsf">{Constants.WHO_AM_I}</a></p>
            <div className="col mt-0 pt-0">
              <a href="https://amzn.to/2Kwukn3" target="_blank">
                <img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08NX4BV2V&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=merch-amz-20&language=en_US" />
              </a>
              <img src="https://ir-na.amazon-adsystem.com/e/ir?t=merch-amz-20&language=en_US&l=li1&o=1&a=B08NX4BV2V" width="1" height="1" border="0" alt="" />
            </div>

          </div>


        </div>


      </div >

    </Router>

  );
}

// export default App;

