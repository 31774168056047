import Utilities from './utilities'

/**
 * @description Google Analytics
 * @author Ed Chavez at gmail dot com
 *
 * @export
 * @class GoogleAnalytics
 */
export class GoogleAnalytics {
  static gtag = window.gtag

  /**
   * @description Send an event to Google Analytics
   *
   * @static
   * @param {EventItemModel} EventItemModel instance
   * @memberof GoogleAnalytics
   */
  static LogGAEvent(EventItemModel) {
    try {
      this.gtag('event', EventItemModel.eventAction, {
        'event_category': EventItemModel.eventCategory,
        'event_label': EventItemModel.eventLabel,
        'value': EventItemModel.eventValue
      });

    } catch (error) {
      Utilities.ErrLog("Failed to send GA event", error);
    }
  }


}

/**
 * @description Model representing an Event to track in GA
 *
 * @author Ed Chavez at gmail dot com
 * @export
 * @class EventItemModel
 */
export class EventItemModel {

  /**
   * @description Creates an instance of EventItemModel.
   * @param {string} eventAction The string that will appear as the event action
   * @param {string eventCategory The string that will appear as the event category
   * @param {string} eventLabel the string taht will appear as the event label
   * @param {number} [eventValue=0] A non-negative integer that will appear as the event value
   * @memberof EventItemModel
   */
  constructor(eventAction, eventCategory, eventLabel, eventValue = 0) {
    this.eventAction = eventAction;
    this.eventCategory = eventCategory;
    this.eventLabel = eventLabel;
    this.eventValue = eventValue
  }
}

export default { GoogleAnalytics, EventItemModel } 