import React from 'react';
import ReactDOM from 'react-dom';
import './custom.scss';
import Utilities from './helpers/utilities'
import App from './App';
import * as serviceWorker from './serviceWorker'
import ScriptLoader from './helpers/scriptloader'
import Covid from './covid'

const covid = new Covid(2);
window.covid = covid;

if (covid.CreateOrUpdate()) {
  covid.FetchStatesData()
    .then(d => {
      covid.SetData(d);
      LoadScript();
    })
} else {
  LoadScript();
}

console.info(Utilities.Author());

function LoadScript() {
  ScriptLoader.LoadScript("https://www.gstatic.com/charts/loader.js")
    .then(result => {
      console.log(`Success message: ${result}`)
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById('root')
      );

    })
    .catch(err => {
      Utilities.ErrLog("The sky fell..", err);
    })

}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
