import Utilities from './utilities'

/**
 * @description Implement FETCH API
 * @description REF: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 * 
 * @author edchavez at gmail dot com
 */
export default class HttpFetch {
  Target;
  Payload;
  Method;

  constructor(target, method, data = {}) {
    this.Target = target;
    this.Payload = data;
    this.Method = method;
  }

  /**
   * Fetch API
   *
   * @returns {Promise}
   * @memberof HttpFetch
   */
  async GetDataAsync() {
    const response = await fetch(this.Target)
      .then(r => {
        if (!r.ok) {
          throw new Error(`Network Error: ${r.status}`);
        }
        return r.json();
      }).catch(e => {
        Utilities.ErrLog("Unhandled exception %o", e)
      });

    return await response;
  }

  /**
   * @description Fetch API POST. Default content type: appliation/json
   *
   * @returns {Promise}
   * @memberof HttpFetch
   */
  async PostDataAsync(contentType = "application/json") {
    const response = await fetch(this.Target, {
      method: this.Method,
      headers: {
        "Content-Type": contentType
      },
      body: JSON.stringify(this.Payload)
    }).then(r => {
      if (!r.ok) {
        throw new Error(`Network Error: ${r.status}`);
      }
      return r.json();
    }).catch(e => {
      Utilities.ErrLog("Unhandled exception %o", e)
    });

    return await response;
  }



}
