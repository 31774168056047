import React from 'react'
import Utilities from './helpers/utilities'
// import * as Constants from './constants'
import { Redirect } from 'react-router-dom'
// import { motion } from 'framer-motion'
import { GoogleAnalytics, EventItemModel } from './helpers/analytics'

/**
 * Google Charts component
 *
 * @export
 * @class GoogleMap
 * @extends {React.Component}
 * @author edchavez at gmail dot com
 */
export default class GoogleMap extends React.Component {

  google = window.google;
  usPopulation = 327000000;



  state = {
    usTotal: 0,
    usTested: 0,
    usPositive: 0,
    usNegative: 0,
    dataTable: [
      ['State', 'Deaths']
    ],
    showState: '',
    redirectToState: false
  }

  constructor(props) {
    super(props);

    this.covid = window.covid;
  }



  componentDidMount() {
    // console.log('is google loaded %o', this.covid);
    // this.covid.Init();
    this.buildData();
    // console.log('done building data, datatable is now %o', this.state.dataTable);
    this.initRegionMap();
  }

  componentDidUpdate() {
    // console.log("GoogleMaps Component didUpdate %o", this.google);
  }


  initRegionMap() {

    this.google.charts.load('current', {
      'packages': ['geochart'],
      // Note: you will need to get a mapsApiKey for your project.
      // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
      'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
    });

    // console.log("done with initRegionsMap %o", this.state.dataTable)
    this.google.charts.setOnLoadCallback(this.drawRegionsMap);
  }


  drawRegionsMap = () => {
    // console.log(this.state)
    // console.log("running drawRegionsMap %o", this.state.dataTable); //this.dataTable);
    var data = this.google.visualization.arrayToDataTable(this.state.dataTable);

    var options = {
      'region': 'US',
      'resolution': 'provinces',
      'datalessRegionColor': "#F5F5F5",
      'defaultColor': '#F5F5F5',
      'keepAspectRatio': true,
      'height': 400,
      'legend': 'none',
      'colorAxis': { minValue: 0, colors: ['ffffff', '#ff0000'] },
      'backgroundColor': '#333940',
      'magnifyingGlass': { enable: true, zoomFactor: 7.5 }
    };

    var chart = new window.google.visualization.GeoChart(document.getElementById('regions'));

    this.google.visualization.events.addListener(chart, 'regionClick', this.getStateData);

    chart.draw(data, options);
  }

  buildData() {
    // console.log('Now running builData...covid object is %o', this.covid)

    // const stateData = covid.GetCovidData();
    const stateData = this.covid.SortByStateDeathsDesc();
    // console.log("this is our data\n%o", stateData);
    // let count = 0
    // let 

    this.setState({
      usTotal: this.covid.GetTotalDeaths(),
      usTested: this.covid.GetTotalTested(),
      usPositive: this.covid.GetTotalPositive(),
      usNegative: this.covid.GetTotalNegative()
    })

    for (let state of stateData) {
      // let int = state.detail.death;
      // console.log(state.detail.death);
      // count += int;
      this.state.dataTable.push([state.state, state.detail.death]);
    }
  }

  /**
   * Bind this Event handler to GoogleMap Component, otherwise, `this` is Google Chart (not this component)
   */
  getStateData = (o) => {
    // ReactDOM.render(
    //   <StateDetail query={o.region} />, document.getElementById("state-detail")
    // );

    let eventData = new EventItemModel('Click', 'Covid Map', o.region)
    GoogleAnalytics.LogGAEvent(eventData);

    // console.log("fired event now it should redirect...%o", this)
    this.setState({
      redirectToState: true,
      showState: o.region
    })

  }

  render() {

    if (this.state.redirectToState === true) {
      return (
        <Redirect to={
          {
            pathname: `/state/${this.state.showState}`,
            // search: ,
            state: { query: `${this.state.showState}` }
          }
        } />
      )
    }

    return (
      <>

        <div className="row mt-5 pt-5 bg-dark">
          <div className="col text-center">
            <h1 className="text-light">US Covid 19 Statistics Map</h1>
            <p className="text-secondary m-0 p-0">Tap/Click Map For State Details</p>
          </div>
        </div>

        <div className="row py-0 bg-dark">
          <div id="regions" className="col text-center m-0 p-0" title="Interactive US Map. Tap or click to obtain Covid-19 statistics for a US State. ">
          </div>
        </div>

        <div className="row py-0 mt-3">
          <div className="col-2"></div>
          <div id="us-data" className="col text-center">
            <h3 className="text-secondary">US Totals</h3>
            <p className="small">Pop: {this.usPopulation.toLocaleString()} (2019)</p>

            <table className="table table-striped shadow">
              <caption className="small">US Data Details (Click/tap on map to view State data)</caption>
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Metric</th>
                  <th scope="col">Total</th>
                  <th scope="col">Pct</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">Deaths</td>
                  <td className="text-danger align-middle">{this.state.usTotal.toLocaleString()}</td>
                  <td>
                    <div className="py-1">
                      <span className="text-danger">{Utilities.GetPct(this.state.usPositive, this.state.usTotal, 3).toLocaleString()}%</span> of Positive Cases ({this.state.usPositive.toLocaleString()} )
                    </div>
                    <div className="border-top py-1">
                      <span className="text-danger">{Utilities.GetPct(this.usPopulation, this.state.usTotal, 3).toLocaleString()}%</span> of US Population ({this.usPopulation.toLocaleString()})
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Tested</td>
                  <td className="align-middle">{this.state.usTested.toLocaleString()}</td>
                  <td>
                    <div>
                      <span className="text-my-warning">{Utilities.GetPct(this.usPopulation, this.state.usTested, 3).toLocaleString()}%</span>  of US Population ({this.usPopulation.toLocaleString()})
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Positive</td>
                  <td className="text-danger align-middle">{this.state.usPositive.toLocaleString()}</td>
                  <td>
                    <div className="py-1">
                      <span className="text-danger">{Utilities.GetPct(this.state.usTested, this.state.usPositive).toLocaleString()}%</span> of Tested Cases ({this.state.usTested.toLocaleString()} )
                    </div>
                    <div className="border-top py-1">
                      {Utilities.GetPct(this.usPopulation, this.state.usPositive, 3).toLocaleString()}% of US Population ({this.usPopulation.toLocaleString()})
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Negative</td>
                  <td className="align-middle">{this.state.usNegative.toLocaleString()}</td>
                  <td>
                    <div className="py-1">
                      {Utilities.GetPct(this.state.usTested, this.state.usNegative).toLocaleString()}% of Tested Cases ({this.state.usTested.toLocaleString()} )
                    </div>
                    <div className="border-top py-3">
                      {Utilities.GetPct(this.usPopulation, this.state.usNegative, 3).toLocaleString()}% of US Population ({this.usPopulation.toLocaleString()})
                    </div>

                  </td>
                </tr>
              </tbody>
            </table>

          </div>
          <div className="col-2"></div>
        </div>

      </>
    )
  }

}