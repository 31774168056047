import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import Utilities from './helpers/utilities'
import Datasets from './helpers/datasets'
import { Link, Redirect } from 'react-router-dom'
import { motion } from 'framer-motion';
import * as Constants from './constants'

export default class StateDetail extends React.Component {

  google = window.google;

  state = {
    population: 0,
    state: "",
    hasError: false,
    detail: {
      "totalTestResults": 0,
      "positive": 0,
      "negative": 0,
      "death": 0,
      "hospitalized": "NA",
      "dateChecked": "",
      "noNegativeData" : false
    }
    // dataTable: [
    //   ['Positive Mortality', 'Total']
    // ]
  }




  /**
   * This is the only place you can set state directly, otherwise use setState()
   * @param {*} props 
   */
  constructor(props) {
    super(props);

    // console.log("State Detail init with props\n%o\nstate:\n%o", props, this.state);
    // console.log("react router dom: %o", props.location)
    this.covid = window.covid;
    // this.CurrentState = this.props.query

  }

  componentDidMount() {
    // console.log("component did mount\nprops: %o\nstate: %o", this.props, this.state)
    this.updateData()

  }

  /**
   * Deprecated
   */
  // componentWillReceiveProps(e) {
  //   console.log("Component received new props %o", e);
  // }

  /**
   * Note: this checks for browser back button (see POP)
   * @param {*} prev 
   */
  componentDidUpdate(prev) {
    // console.log("Component did update prev:  %o\ncurrent: %o", prev, this.props);
    // console.log("should I call method? %o", prev.query !== this.props.query)
    this.initChart();
    if (!this.state.hasError && this.props.history.action !== "POP" && prev.location.state.query !== this.props.location.state.query) {
      this.updateData()
    }
  }



  getStatePopulation() {
    // return Datasets.GetStatePopulation(this.state["state"]);
    return Datasets.GetStatePopulation(this.props.location.state.query);
  };

  updateData() {
    // this.setState(this.covid.GetStateData(this.props.query));
    // this.setState({ usPopulation: this.getStatePopulation() });
    // console.log('running update data....')

    if (this.propsError()) {
      this.setState({ hasError: true });
    } else {
      this.setState((prevState, currProp) => {
        // const detail = this.covid.GetStateData(currProp.location.state.query);
        // const tableData = this.state.dataTable.push([detail.detail.positive, detail.detail.death])
        // const merged = Object.assign(detail, tableData)
        // console.log("detail is %o", merged)
        return (this.covid.GetStateData(currProp.location.state.query));
      })
      this.setState((state) => {
        return { population: this.getStatePopulation() }
      });
    }
  }

  initChart() {
    // console.log("build data invoked, state data is %o", this.state)
    this.google.charts.load('current', {
      'packages': ['corechart']
      // Note: you will need to get a mapsApiKey for your project.
      // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
      // 'mapsApiKey': 'AIzaSyD-9tSrke72PouQMnMX-a7eZSW0jkFMBWY'
    });

    // console.log("done with initRegionsMap %o", this.state.dataTable)
    this.google.charts.setOnLoadCallback(this.drawPieChart);
  }

  drawPieChart = () => {
    // console.log(this.state)
    // console.log("running drawRegionsMap %o", this.state.dataTable); //this.dataTable);

    // console.log("Draw pie chart, compoenent state state s %o", this.state);

    var data = this.google.visualization.arrayToDataTable([
      ['Result', 'Total'],
      ['Tested Positive', this.state.detail.positive],
      ['Deaths', this.state.detail.death]
    ]);

    var options = {
      // title: 'Mortality (Tested Positive)',
      // titleTextStyle: {
      //   fontName: 'Roboto Mono',
      //   fontSize: 16
      // },
      is3D: true,
      tooltip: {
        text: 'value'
      },
      pieSliceText: 'value',
      piceSliceTextStyle: {
        fontName: 'Roboto Mono'
      },
      slices: [
        {
          color: '#fca458', textStyle:
            { fontName: 'Roboto Mono', color: '#343A40' }
        }, {
          textStyle:
            { fontName: 'Roboto Mono', color: '#fff' }
          , color: '#dc3545', offset: 0.1
        }],
      height: 500,
      chartArea: {
        top: 20,
        width: '100%'
      },
      legend: {
        position: 'bottom',
        textStyle: {
          fontName: 'Roboto Mono',
          fontSize: 14
        }
      }
      // 'region': 'US',
      // 'resolution': 'provinces',
      // 'datalessRegionColor': "#F5F5F5",
      // 'defaultColor': '#F5F5F5',
      // 'keepAspectRatio': true,
      // 'height': 400,
      // 'legend': 'none',
      // 'colorAxis': { minValue: 0, colors: ['ffffff', '#ff0000'] },
      // 'backgroundColor': '#333940',
      // 'magnifyingGlass': { enable: true, zoomFactor: 7.5 }
    };

    var chart = new window.google.visualization.PieChart(document.getElementById('pie'));
    chart.draw(data, options);
  }

  propsError() {
    if (this.props.location.state === null || typeof (this.props.location.state) === "undefined") {
      Utilities.ErrLog("Browser nav issue", this.props.location);
      return true;
    }
  }


  render() {
    if (this.state.hasError) {
      return (
        <Redirect to="/" />
      )
    }

    return (
      <motion.div
        initial={Constants.PAGE_TRANSITION.out}
        animate={Constants.PAGE_TRANSITION.in}
        exit={Constants.PAGE_TRANSITION.out}
      // variants={Constants.PAGE_TRANSITION}        
      >



        <div className="row pt-5 mt-5">

          <div className="col-2 align-self-center text-center">
            <Link title="Back to US Cumulative Data" aria-label="Back to US Data" to="/"><FontAwesomeIcon icon={faArrowAltCircleLeft} className="fa-3x" /></Link>
          </div>


          <div id="us-data" className="col text-center">
            <h3 className="text-secondary">{this.state.state} State Data Detail</h3>
            <p className="small">
              Pop: {this.state.population.toLocaleString()} (2019) <br />
              <span className="text-danger bold">Data updated: {this.state.detail.dateChecked}</span>
            </p>

            <table className="table table-striped shadow">
              <caption className="small">{this.state.state} State Data Details as of {this.state.detail.dateChecked}. Source: <a href="https://covidtracking.com/" target="_credits">Covid Tracking Project</a></caption>
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Metric</th>
                  <th scope="col">Total</th>
                  <th scope="col">Pct</th>

                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">Deaths</td>
                  <td className="text-danger align-middle">{this.state.detail.death.toLocaleString()}</td>
                  <td>
                    <div className="py-1">
                      <span className="text-danger">{Utilities.GetPct(this.state.detail.positive, this.state.detail.death).toLocaleString()}%</span> of Positive Cases ({this.state.detail.positive.toLocaleString()} )
                    </div>
                    <div className="border-top py-1">
                      <span className="text-danger">{Utilities.GetPct(this.state.population, this.state.detail.death, 3).toLocaleString()}%</span> of {this.state.state} Population ({this.state.population.toLocaleString()})
                      </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Tested</td>
                  <td className="align-middle">{this.state.detail.totalTestResults.toLocaleString()}</td>
                  <td>
                    <div>
                      <span className="text-my-warning">{Utilities.GetPct(this.state.population, this.state.detail.totalTestResults, 3).toLocaleString()}%</span>  of {this.state.state} Population ({this.state.population.toLocaleString()})
                      </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Positive</td>
                  <td className="text-danger align-middle">{this.state.detail.positive.toLocaleString()}</td>
                  <td>
                    <div className="py-1">
                      <span className="text-danger">{Utilities.GetPct(this.state.detail.totalTestResults, this.state.detail.positive).toLocaleString()}%</span> of Tested Cases ({this.state.detail.totalTestResults.toLocaleString()} )
                    </div>
                    <div className="border-top py-1">
                      {Utilities.GetPct(this.state.population, this.state.detail.positive, 3).toLocaleString()}% of {this.state.state} Population ({this.state.population.toLocaleString()})
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={`align-middle ${this.state.detail.noNegativeData ? "text-muted" : ""}`}>Negative {this.state.detail.noNegativeData ? "(N/A)" : ""}</td>
                  <td className={`align-middle ${this.state.detail.noNegativeData ? "text-muted" : ""}`}>{this.state.detail.negative.toLocaleString()}</td>
                  <td>
                    <div className={`py-1 ${this.state.detail.noNegativeData ? "text-muted" : ""}`}>
                      {Utilities.GetPct(this.state.detail.totalTestResults, this.state.detail.negative).toLocaleString()}% of Tested Cases ({this.state.detail.totalTestResults.toLocaleString()} )
                </div>
                    <div className={`border-top py-3 ${this.state.detail.noNegativeData ? "text-muted" : ""}`}>
                      {Utilities.GetPct(this.state.population, this.state.detail.negative, 3).toLocaleString()}% of {this.state.state} Population ({this.state.population.toLocaleString()})
                </div>

                  </td>
                </tr>
              </tbody>
            </table>

          </div>


          <div className="col-2"></div>

        </div>

        <div className="row">
          <div className="col-12 text-center">
            <h4 className="text-secondary">Positive Mortality</h4>
          </div>

          <div id="pie" className="col-12 text-center">

          </div>
        </div>



      </motion.div>
    )
  }
}