
/**
 * @description Utility classes
 *
 * @export
 * @class Utilities
 * @author edchavez at gmail dot com
 */
export default class Utilities {

  /**
   * Class Method. Invoked directly
   * .e.g. Utilities.HelloWorld()
   *
   * @static
   * @param {string} [msg='']
   * @returns {string}
   * @memberof Utilities
   */
  static HelloWorld(msg = '') {
    return "Hello World from Utilities " + msg;
  }

  /**
   *
   *
   * @static
   * @returns Ed Chavez
   * @memberof Utilities
   */
  static Author() {
    return {
      "Ed Chavez":
      {
        "nutshell": "Far from young, but still foolish",
        "wish": "I use Azure/Google Cloud for my personal apps. I hope to do so professionally as well.",
        "quotes":
          [
            "I don't know, so I'll find out",
            "I enjoy owning it",
            "Mistakes are learning cycles",
            "Just keep swimming"
          ],
        "stack overflow": {
          "motto": "I want to learn as much as try to help",
          "url": "https://stackoverflow.com/users/304683/edsf"
        },
        "ecommerce": "you got me at hello world",
        "github": "https://www.github.com/edsf"
      }
    }
  }

  /**
   * Instance method. Create an instance to invoike
   * e.g. new Utilities().NonStatic()
   *
   * @returns {string}
   * @memberof Utilities
   */
  NonStatic() {
    return "None Static from Utilities";
  }

  /**
   * @description A better IsNaN (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN)
   * @param input The value to evaluate
   * @returns True or False result of evaulation
   */
  static IsNumeric(input) {
    if (input === null)
      return false;
    var n = Number(input);
    return n === n;
  }

  /**
   * Checks if a string is a valid absolute URL
   * @param input The string to check
   * @returns Boolean result of evaluation
   */
  static IsUrl(input) {
    if (input.trim().length === 0)
      return false;
    try {
      var url = new URL(input);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * A very liberal input string sanitizer
   * @param input The string to sanitize
   * @returns Sanitized string
   */
  static SanitizeFreeTxt(input) {
    if (input.trim().length === 0)
      return input;
    var re = /[^A-Za-z0-9\-/.,?!@\s:;'"()&%$]/gmi;
    return input.replace(re, '');
  }

  /**
   * @description Serializes object to string
   * @param data Object to serialize (to string)
   * @returns String
   */
  static SerializeObject(data) {
    if (data != null) {
      return JSON.stringify(data);
    } else {
      this.ErrLog("Cannot serialize. Object is null: ", data);
    }
  }

  /**
   * @description Parses string into an object
   * @returns JSON representation of T 
   */
  static DeserializeObject(str) {
    if (str !== "") {
      return JSON.parse(str);
    } else {
      this.ErrLog("Cannot serialize. Empty string", null);
    }
  }

  /**
   * @description Given an ISO 8601 UTC Date Time string: 2020-04-06T19:30:00Z, return a locale date string
   * @returns Locale Datetime string
   */
  static ReturnLocaleDateString(str) {
    let dateInt = Date.parse(str);
    if (dateInt) {
      return new Date(dateInt).toLocaleString();
    }
    return null;
  }

  /**
   * @description Debugging helper using browser console
   * @param msg Error message
   * @param e Exception data
   */
  static ErrLog(msg, e) {
    console && console.error("%s %o", msg, e);
  }

  /**
   * @description Calculate percentage
   *
   * @static
   * @param {*} total The total to obtain percent from
   * @param {*} count The count out of the total
   * @param {number} [scale=2] Scale (aka decimal places). Default is 2
   * @returns
   * @memberof Utilities
   */
  static GetPct(total, count, scale = 2) {
    if (this.IsNumeric(total) && this.IsNumeric(count)) {
      return ((count / total) * 100).toFixed(scale);
    } else {
      throw Error(`One or more inputs are invalid.\n total: ${total}\n count ${count} `)
    }
  }

}

